import React, { ReactElement, useLayoutEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Layout } from '../components/layout'
import { Seo } from '../components/seo'
import { HeroPanel } from '../components/heroPanel'

const IndexPage = () => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          tagline
          linkedInProfile
          phone
          email
        }
      }
    }
  `)

    const [heroPanel, setHeroPanel] = useState<ReactElement>()
    useLayoutEffect(() => {
        setHeroPanel(<HeroPanel
            imageAlt={data.site.siteMetadata?.title}
            title={data.site.siteMetadata?.title}
            tagline={data.site.siteMetadata?.tagline}
            linkedInProfile={data.site.siteMetadata?.linkedInProfile}
            phone={data.site.siteMetadata?.phone}
            email={data.site.siteMetadata?.email}
        />)
    }, [])

    return (
        <Layout>
            <Seo title={data.site.siteMetadata?.title} description={data.site.siteMetadata?.tagline}/>
            {heroPanel}
        </Layout>
    )
}

export default IndexPage
