import React, { useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { RewardElement } from 'react-rewards'

import './heroPanel.scss'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { EmojiReplacer } from './emojiReplacer';

type Props = {
    imageAlt: string;
    title: string;
    tagline: string;
    linkedInProfile?: string;
    phone?: string;
    email?: string;
}

export const HeroPanel: React.FC<Props> = ({imageAlt, tagline, title, linkedInProfile, phone, email}: Props) => {
    let reward: RewardElement | null = null;

    useEffect(() => {
        reward && reward.rewardMe()
    }, [reward])

    return (
        <div className="hero-panel">
            <Link to="/cv" className="no-colour"><StaticImage
                src={`../images/hero.png`}
                quality={95}
                alt={imageAlt}
            /></Link>
            <h1>{title}</h1>
            <h2 dangerouslySetInnerHTML={{ __html: EmojiReplacer(tagline).split('|').join('&nbsp;|&nbsp;') }} />
            <section>
                <Link to="/cv" className="text-link">CV</Link>
                {linkedInProfile && <a href={`https://www.linkedin.com/in/${linkedInProfile}`} aria-label="LinkedIn"><FontAwesomeIcon icon={faLinkedin} /></a>}
                {email && <a href={`mailto:${email}`} aria-label="Email Leanne"><FontAwesomeIcon icon={faEnvelope} /></a>}
                {phone && <a href={`tel:${phone}`} aria-label="Call Leanne"><FontAwesomeIcon icon={faMobileAlt} /></a>}
            </section>
        </div>
    )
}
